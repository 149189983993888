import menuDisplayManagerApi from "api/menuDisplayManagerApi";
import menuItemManagerApi from "api/menuItemManagerApi";
import productManagerApi from "api/productManagerApi";

export { useMenuDisplayManagerActions };

function useMenuDisplayManagerActions() {
   const getMainMenuDisplays = async (params) => {
      try {
         return await menuDisplayManagerApi.getMainMenuDisplay(params);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const getMenuDisplays = async (params) => {
      try {
         return await menuDisplayManagerApi.gets(params);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const getMenuDisplayById = async (params) => {
      try {
         return await menuDisplayManagerApi.get(params);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const getProductNoneItems = async (params) => {
      try {
         return await productManagerApi.getProductNoneItems(params);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const putMenuItemDisplay = async (body) => {
      try {
         return await menuItemManagerApi.putMenuItemDisplay(body);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const getMenuItemsNoneStore = async (params) => {
      try {
         return await menuItemManagerApi.getMenuItemsNoneStore(params);
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   return {
      getMainMenuDisplays,
      getMenuDisplays,
      getMenuDisplayById,
      getProductNoneItems,
      putMenuItemDisplay,
      getMenuItemsNoneStore
   };
}
